export const state = {
  formData: {
    campaign_name: "",
    excel_contacts: [],
    displayedHeaders: [],
    title: "",
    message: "",
    sender_id: "",
    recipients: "",
    recipients_format: "",
    from: "",
    messageType: "",
    groupIDs: [],
    body: "",
    type: "",
    date_time: "",
    audio: [],
    voice_template_id: "",
    headers: [],
    header: "",
    hideHeaders: false,
    id: "",
    perMessage: "",
    purpose: "",
    selectedKey: "",
    isEdit: false,
    pageTitle: "",
    sender: "",
    totalNumberOfRecipient: 0,
    campaign_id: "",
  },
  nextPage: "",
  prevPage: "",
  currentPage: "",
  fromView: false,
  messageAction: "",
  fromExcel: false,
  fromSchedule: false,
  showFeedback: false,
  showFeedbackModal: false,
  showCategories:false,
  showCategoriesModal:false,
};
export const mutations = {
  SET_FROM_SCHEDULE(state, payload) {
    state.fromSchedule = payload;
  },
  SET_FROM_EXCEL(state, payload) {
    state.fromExcel = payload;
  },
  SET_FROM_VIEW(state, payload) {
    state.fromView = payload;
  },
  ADD_FORM_DATA(state, payload) {
    state.formData = {
      ...state.formData,
      ...payload,
    };
  },
  CLEAR_FORM_DATA(state) {
    const emptyState = {
      campaign_name: "",
      excel_contacts: [],
      displayedHeaders: [],
      title: "",
      message: "",
      sender_id: "",
      recipients: "",
      recipients_format: "",
      from: "",
      messageType: "",
      groupIDs: [],
      body: "",
      type: "",
      date_time: "",
      audio: [],
      voice_template_id: "",
      headers: [],
      header: "",
      hideHeaders: false,
      id: "",
      perMessage: "",
      purpose: "",
      selectedKey: "",
      isEdit: false,
      pageTitle: "",
      sender: "",
      totalNumberOfRecipient: 0,
      campaign_id: "",
    };
    state.formData = {
      ...emptyState,
    };
  },

  UPDATE_NEXT_PAGE(state, nextPage) {
    state.nextPage = nextPage;
  },

  UPDATE_PREV_STATE(state, prevPage) {
    state.prevPage = prevPage;
  },
  UPDATE_CURRENT_PAGE(state, currentPage) {
    state.currentPage = currentPage;
  },
  UPDATE_SINGLE_FIELD(state, { key, value }) {
    state.formData[key] = value;
  },
  SET_MESSAGE_ACTION(state, action) {
    state.messageAction = action;
  },
  SHOW_FEEDBACK(state, payload) {
    state.showFeedback = payload;
  },
  SHOW_FEEDBACK_MODAL(state, payload) {
    state.showFeedbackModal = payload;
  },
  SHOW_CATEGORIES_MODAL(state, payload) {
    state.showCategoriesModal = payload;
  },
  GET_FORM_DATA(state, payload) {
    state.getFormData = payload;
  },
};

export const actions = {
  setFromSchedule({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_FROM_SCHEDULE", payload);
      resolve();
    });
  },
  setFromExcel({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_FROM_EXCEL", payload);
      resolve();
    });
  },
  setMessagAction({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_MESSAGE_ACTION", payload);
      resolve();
    });
  },
  setFromView({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_FROM_VIEW", payload);
      resolve();
    });
  },
  getFormData({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("GET_FORM_DATA", payload);
      resolve();
    });
  },
  addFormData({ commit }, { prevPage, nextPage, formData, currentPage }) {
    return new Promise((resolve, reject) => {
      commit("ADD_FORM_DATA", formData);
      commit("UPDATE_NEXT_PAGE", nextPage);
      commit("UPDATE_PREV_STATE", prevPage);
      commit("UPDATE_CURRENT_PAGE", currentPage); 
    });
    
  },
  clearFormData({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_FORM_DATA");
      commit("UPDATE_NEXT_PAGE", "");
      commit("UPDATE_PREV_STATE", "");
      commit("UPDATE_CURRENT_PAGE", "");
      commit("SET_MESSAGE_ACTION", "");
      commit("SET_FROM_VIEW", "");
      commit("SET_FROM_EXCEL", "");
      commit("SET_FROM_SCHEDULE", "");
      resolve();
    });
  },
  updateNextPage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_NEXT_PAGE", payload);
      resolve();
    });
  },
  updateCurrentPage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_CURRENT_PAGE", payload);
      resolve();
    });
  },
  updatePrevPage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_PREV_STATE", payload);
      resolve();
    });
  },
  updateSingleField({ commit }, { key, value }) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_SINGLE_FIELD", { key, value });
      resolve();
    });
  },
  showFeedback({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SHOW_FEEDBACK", payload);
      resolve();
    });
  },
  showFeedbackModal({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SHOW_FEEDBACK_MODAL", payload);
      resolve();
    });
  },
  showCategoriesModal({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SHOW_CATEGORIES_MODAL", payload);
      resolve();
    });
  },
};

export const getters = {
  getFormData: (state) => state.formData,
  nextPage: (state) => state.nextPage,
  prevPage: (state) => state.prevPage,
  currentPage: (state) => state.currentPage,
  messageAction: (state) => state.messageAction,
  getShowFeedback: (state) => state.showFeedback,
  getShowFeedbackModal: (state) => state.showFeedbackModal,
  getShowCategoriesModal: (state) => state.showCategoriesModal, 
};
