import AdminService from "@/services/admin.service";

export const state = {
    promoCodes: []
}

export const mutations = {
    ADD_PROMOCODES(state, payload){
        state.promoCodes.unshift(payload)
    },  
    SET_PROMOCODES(state, payload){
        let sortedData = payload.sort( (a,b) => new Date(b.created_at) - new Date(a.created_at))
        state.promoCodes = sortedData

    },
    TOGGLE_PROMO_CODE(state, payload){
        const newPromoCodes = state.promoCodes.map( promo => {
        if(promo.id == payload.id) {
                let status = promo.status == '0' ? '1' : '0'
                return {
                    ...promo,
                    status: status
                }
            }
            return promo
        })
        state.promoCodes = [...newPromoCodes]
    }
}

export const actions = {
    createPromocode({commit},payaload) {
        return new Promise( (resolve, reject) => {
            AdminService.post(`api/promo/code/create`,payaload)
                .then( ({ data: {data, message }}) => {
                    if(data) {
                        commit('ADD_PROMOCODES',data)
                    }
                    resolve(message)
                }).catch( err => reject(err))
        })
    },
    togglePromoCode({commit},payload) {
        return new Promise( (resolve, reject) => {
            AdminService.post(`api/promo/code/toggle`,payload)
                .then( ({ data: {data, message }}) => {
                    if(data) {
                        commit('TOGGLE_PROMO_CODE',payload)
                    }
                    resolve(message)
                }).catch( err => reject(err))
        })
    },
    getPromoCodes({commit}) {
        return new Promise( (resolve, reject) => {
            AdminService.get(`api/promo/codes/get`)
                .then( ({ data: {data, message }}) => {
                    if(data) {
                        commit('SET_PROMOCODES',data)
                    }
                    resolve(message)
                }).catch( err => reject(err))
        })
    },
}

export const getters = {
    promoCodes: state => state.promoCodes,
    activePromoCodes: state => state.promoCodes.filter( code => code.status == "1"),
    inactivePromoCodes: state => state.promoCodes.filter( code => code.status != "1"),
    expiredPromoCodes: state => state.promoCodes.filter( code => new Date(code.expiry_date) < new Date() ),
    totalPromoCodesUesd: state => {
        let totalCount = 0
        state.promoCodes.forEach( promo => totalCount += promo.usage_count)
        return totalCount
    }
}
