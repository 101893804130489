import apiService from "../../services/api.service";

export const actions = {
    sendCategories({commit}, payload) {
        return new Promise( (resolve, reject) => {
            apiService.post(`api/categorize/users`,payload)
                .then(  ({ data }) => {
                    resolve(data)
                }).catch( err => reject(err))
        })
    },
   
}