<template>
<div class="wrapper">
    <div class="header">
        <h3>Notice</h3>
    </div>
    <div class="__body">
        <div class="__circle">
            <img src="../../assets/sucess.svg" alt="img">
        </div>
        <p class="__text">
           {{message}}
        </p>
    </div>
</div>
</template>

<script>
export default {
    name: 'success-message',
    props: {
        message: {
            type: String,
            required:true
        }
    }
}
</script>

<style scoped>
.wrapper{
    width:100%; 
    display:flex;
    align-items:center;
    padding:2em 1em .5em 1em;
    flex-direction:column;
}
.header{
    width:100%;
    border-bottom: 0.565854px solid #F3F3F4;
}
h3{
    font-style: normal;
    font-weight: 500;
    font-size: 10.1854px;
    line-height: 12px;
    color: #575761;
    text-align: center;
}
.__text{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #484A4F;
}
.__body{
    width:100%;
    display:flex;
    justify-content: center;
    align-items:center;
    flex-direction:column;
}
.__circle{
    width: 48px;
    border-radius:24px;
    height: 48px;
    left: 148.02px;
    display:flex;
    align-items:center;
    justify-content:center;
    top: 87.76px;
    background: rgba(54, 217, 98,0.1);
    margin-bottom:2em;
    margin-top:2em;

}
</style>