import apiService from "../../services/api.service"

export const state = {
    industries: []
}

export const mutations = {
  SETINDUSTRY(state,payload) {
        state.industries = payload
    }
}

export const actions = {
    getIndustryReporting({commit}) {
        return new Promise( (resolve, reject) => {
          apiService.get(`api/industries/get`)
                .then( ({ data: { data }}) => {
                    if(data) {
                        commit('SETINDUSTRY',data)
                    }
                    resolve(data)
                }).catch( err => reject(err))
        })
    },
}

export const getters = {
    industries: state => state.industries
}
