import AdminService from "@/services/admin.service";

export const state = {
  resellers: [],
  resellerReports: [],
  resellerReportsVoice: [],
  resellerDomainNames: [],
  resellerUsers: {},
};

export const mutations = {
  SET_RESELLERS(state, payload) {
    state.resellers = payload;
  },

  UPDATE_RESELLER(state, payload) {
    state.resellers = state.resellers.map((reseller) => {
      if (reseller.id === payload.id) {
        return payload;
      }
      return reseller;
    });
  },
  DECLINE_RESELLER(state, payload) {
    state.resellers = state.resellers.filter(
      (reseller) => reseller.id !== payload.id
    );
  },
  SET_RESELLER_REPORTS(state, payload) {
    state.resellerReports = payload;
  },
  SET_RESELLER_REPORTS_VOICE(state, payload) {
    state.resellerReportsVoice = payload;
  },
  SET_RESELLER_DOMAIN_NAMES(state, payload) {
    state.resellerDomainNames = payload;
  },
  CLEAR_RESELLER_REPORTS(state) {
    state.resellerReports = [];
  },
  CLEAR_RESELLER_REPORTS_VOICE(state) {
    state.resellerReportsVoice = [];
  },
  SET_RESELLER_USERS(state, payload) {
    state.resellerUsers = payload;
  },
};

export const actions = {
  getPendingResellers({ commit }) {
    return new Promise((resolve, reject) => {
      AdminService.get(`api/reseller/pending/requests`)
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_RESELLERS", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getCompletedResellers({ commit }) {
    return new Promise((resolve, reject) => {
      AdminService.get(`api/reseller/complete/requests`)
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_RESELLERS", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getDeclinedResellers({ commit }) {
    return new Promise((resolve, reject) => {
      AdminService.get(`api/reseller/declined/requests`)
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_RESELLERS", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  editResellerConfiguration({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/reseller/update/request`, payload)
        .then(({ data }) => {
          commit("UPDATE_RESELLER", data.data);
          resolve(data.message);
        })
        .catch((err) => reject(err));
    });
  },
  confirmReseller({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/reseller/request/complete`, payload)
        .then(({ data }) => {
          commit("DECLINE_RESELLER", payload);
          resolve(data.message);
        })
        .catch((err) => reject(err));
    });
  },
  declineReseller({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/reseller/request/decline`, payload)
        .then(({ data }) => {
          commit("DECLINE_RESELLER", data.data);
          resolve(data.message);
        })
        .catch((err) => reject(err));
    });
  },
  // Reports
  getResellerSMSReports({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/reseller/sms/campaigns`, payload)
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_RESELLER_REPORTS", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getResellerVoiceReports({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/reseller/voice/campaigns`, payload)
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_RESELLER_REPORTS_VOICE", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getResellerDomainNames({ commit }) {
    return new Promise((resolve, reject) => {
      if (state.resellerDomainNames.length) {
        resolve(state.resellerDomainNames);
        return;
      }
      AdminService.get(`api/reseller/domains`)
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_RESELLER_DOMAIN_NAMES", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getResellerUsers(
    { commit },
    payload = {
      search: "",
      page: 1,
    }
  ) {
    return new Promise((resolve, reject) => {
      AdminService.get(
        `api/get/reseller/users?search=${payload.search}&page=${
          payload?.page || 1
        }`
      )
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_RESELLER_USERS", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },

  clearResellerReports({ commit }) {
    commit("CLEAR_RESELLER_REPORTS");
  },
  clearResellerReportsVoice({ commit }) {
    commit("CLEAR_RESELLER_REPORTS_VOICE");
  },
};

export const getters = {
  resellers: (state) => state.resellers,
  resellerReports: (state) => state.resellerReports,
  resellerDomainNames: (state) => state.resellerDomainNames,
  resellerReportsVoice: (state) => state.resellerReportsVoice,
  resellerUsers: (state) => state.resellerUsers,
};
