import store from "@/state/store";
import storage from "@/services/storage";
const Storage = new storage();
import logintType from "./../helpers/loginType";
let currentUser = Storage.getSavedState("currentUser");

const routes = [
  {
    path: "/signup",
    name: "signup",
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (currentUser && currentUser.token) {
          // Redirect to the home page instead
          next({ name: "overview" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/SignUp.vue"),
  },
  {
    path: "/signin",
    name: "signin",
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (currentUser && currentUser.token) {
          // Redirect to the home page instead
          next({ name: "overview" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/SignIn.vue"),
  },
  {
    path: "/acquire_reset_pin",
    name: "acquire_reset_pin",
    meta: {
      requireAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/auth/AqcuireRestPassword.vue"
      ),
  },
  {
    path: "/forget-password",
    name: "forget-password",
    meta: {
      requireAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/ResetPassword.vue"),
  },
  {
    path: "/verify-account",
    name: "verify_account",
    meta: {
      requireAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/VerifyAccount.vue"),
  },
  {
    path: "/receive_auth_code",
    name: "receive_auth_code",
    meta: {
      requireAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/auth/VerifyAccount2.vue"
      ),
  },
  {
    path: "/refer",
    name: "refer",
    meta: {
      requireAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/RefereAFriend.vue"),
  },
  {
    path: "/refer_a_friend",
    name: "refer_a_friend",
    meta: {
      requireAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/auth/ShareReferealcode.vue"
      ),
  },
  {
    path: "/login_type",
    name: "login_type",
    meta: {
      requireAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/dashboard/LoginType.vue"
      ),
  },
  {
    path: "/",
    redirect: "/signin",
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch("auth/logOut");
        next({ name: "signin", query: { redirectFrom: routeTo.fullPath } });
      },
    },
  },
  {
    path: "/doughnut",
    name: "doughnut",
    meta: { authRequired: false },
    component: () => import("../views/dashboard/messages/dougnut.vue"),
  },
  {
    path: "/help",
    name: "help",
    meta: { authRequired: true },
    redirect: "/help/getting_started",
    component: () => import("../views/help/index.vue"),
    children: [
      {
        path: "/help/getting_started",
        name: "getting_started",
        meta: { authRequired: true },
        component: () => import("../views/help/GettingStarted.vue"),
      },
      {
        path: "/help/faqs",
        name: "faqs",
        meta: { authRequired: true },
        component: () => import("../views/help/Faq.vue"),
      },
      {
        path: "/help/managing_contacts",
        name: "managing contacts",
        meta: { authRequired: true },
        component: () => import("../views/help/managincontacts.vue"),
      },
      {
        path: "/help/quick_messaging",
        name: "quick messaging",
        meta: { authRequired: true },
        component: () => import("../views/help/quickmessaging.vue"),
      },
      {
        path: "/help/reports",
        name: "reports",
        meta: { authRequired: true },
        component: () => import("../views/help/reports.vue"),
      },
      {
        path: "/help/voice_messaging",
        name: "voice messaging",
        meta: { authRequired: true },
        component: () => import("../views/help/voicemessaging.vue"),
      },
    ],
  },
  // dashboard routes
  {
    path: "/dashboard",
    name: "dashboard",
    // redirect: '/overview',
    meta: {
      authRequired: true,
    },
    component: () => import("../views/dashboard/main/main.vue"),
    children: [
      {
        path: "/overview",
        name: "overview",
        meta: {
          authRequired: true,
        },
        component: () => import("../views/dashboard/main/overview.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        redirect: { name: "settings" },
        meta: { authRequired: true },
        component: () => import("@/views/dashboard/profile/profile.vue"),
        children: [
          {
            path: "/profile-settings",
            name: "settings",
            meta: { authRequired: true },
            component: () => import("@/views/dashboard/profile/index.vue"),
          },
          {
            path: "/referral-settings",
            name: "referral",
            meta: { authRequired: true },
            component: () =>
              import("@/views/dashboard/profile/ReferralCode.vue"),
          },
          {
            path: "/change-password",
            name: "changepassword",
            meta: { authRequired: true },
            component: () =>
              import("@/views/dashboard/profile/changepassword.vue"),
          },
          {
            path: "/change-phone-number",
            name: "changephonenumber",
            meta: { authRequired: true },
            component: () =>
              import("@/views/dashboard/profile/changenumber.vue"),
          },
          {
            path: "/change-language",
            name: "chang language",
            meta: { authRequired: true },
            component: () =>
              import("../views/dashboard/profile/changelanguage.vue"),
          },
          {
            path: "/email-and-notification",
            name: "email and notification",
            meta: { authRequired: true },
            component: () =>
              import("@/views/dashboard/profile/emailandnotifications.vue"),
          },
          {
            path: "/default-account-settings",
            name: "default account settings",
            meta: { authRequired: true },
            component: () =>
              import("@/views/dashboard/profile/changeaccountdefault.vue"),
          },
          {
            path: "/change-email",
            name: "change email",
            meta: { authRequired: true },
            component: () =>
              import("@/views/dashboard/profile/changeemail.vue"),
          },
        ],
      },
      {
        path: "/wallet",
        name: "wallet",
        meta: { authRequired: true },
        component: () => import("../views/dashboard/wallet/index.vue"),
      },
      {
        path: "/wallet/wallet-history",
        name: "wallet-history",
        meta: { authRequired: true },
        component: () => import("../views/dashboard/wallet/WalletHistory.vue"),
      },
      {
        path: "/wallet/bundle-history",
        name: "bundle-history",
        meta: { authRequired: true },
        component: () => import("../views/dashboard/wallet/BundleHistory.vue"),
      },
      {
        path: "/sms/messages",
        name: "messages",
        meta: {
          authRequired: true,
          beforeResolve(routeTo, routeFrom, next) {
            if (logintType() == "sms") {
              next();
            } else {
              next({ name: "voice-channel" });
            }
          },
        },
        component: () => import("../views/dashboard/messages/index.vue"),
      },
      {
        path: "/sms/scheduled_messages",
        name: "scheduled messages",
        meta: {
          authRequired: true,
          beforeResolve(routeTo, routeFrom, next) {
            if (logintType() == "sms") {
              next();
            } else {
              next({ name: "scheduled voice message" });
            }
          },
        },
        component: () =>
          import("../views/dashboard/messages/scheduledMessages.vue"),
      },
      {
        path: "/sms/international-messages",
        name: "international messages",
        meta: {
          authRequired: true,
          beforeResolve(routeTo, routeFrom, next) {
            if (logintType() == "sms") {
              next();
            } else {
              next({ name: "international voice messages" });
            }
          },
        },
        component: () =>
          import("../views/dashboard/messages/internationalMessages.vue"),
      },
      {
        path: "/user/contacts",
        name: "contacts",
        meta: { authRequired: true },
        component: () => import("@/views/dashboard/contacts/index.vue"),
      },
      {
        path: "/calender",
        name: "calender",
        meta: { authRequired: false },
        component: () => import("../components/global/Calender.vue"),
      },
      {
        path: "/group/:id/contacts",
        name: "group contacts",
        meta: { authRequired: true },
        component: () =>
          import("../views/dashboard/contacts/contactsInGroup.vue"),
      },
      {
        path: "/birthday-settings/save",
        name: "save birthday settings",
        meta: { authRequired: true },
        component: () => import("../views/dashboard/birthdayApp/index.vue"),
      },
      {
        path: "/birthday-settings/contacts",
        name: "manage birthday settings",
        meta: { authRequired: true },
        component: () => import("../views/dashboard/birthdayApp/BirthDay.vue"),
      },
      {
        path: "/notifications",
        name: "notifications",
        meta: { authRequired: true },
        component: () => import("../views/dashboard/notifications/index.vue"),
      },
      {
        path: "/sms/campaign",
        name: "sms campaign",
        meta: { authRequired: true },
        redirect: { name: "history" },
        component: () => import("../views/dashboard/reporting/index.vue"),
        children: [
          {
            path: "/sms/campaign/history",
            name: "history",
            meta: {
              authRequired: true,
              beforeResolve(routeTo, routeFrom, next) {
                if (logintType() == "sms") {
                  next();
                } else {
                  next({ name: "voice campaign history" });
                }
              },
            },
            component: () => import("../views/dashboard/reporting/home.vue"),
          },
          {
            path: "/voice/campaign/history",
            name: "voice campaign history",
            meta: {
              authRequired: true,
              beforeResolve(routeTo, routeFrom, next) {
                if (logintType() == "voice_messages") {
                  next();
                } else {
                  next({ name: "history" });
                }
              },
            },
            component: () => import("../views/dashboard/reporting/home.vue"),
          },
          {
            path: "/sms/campaign/:id/details",
            name: "sms campaign details",
            meta: { authRequired: true },
            component: () =>
              import("../views/dashboard/reporting/campaignDetails.vue"),
          },
          // "/voice/campaign/:id/details"
          {
            path: "/voice/campaign/:id/details",
            name: "campaign details",
            meta: { authRequired: true },
            component: () =>
              import("../views/dashboard/reporting/campaignDetails.vue"),
          },
          {
            path: "/sms/campaign/delivery_report",
            name: "delivery report",
            meta: { authRequired: true },
            component: () =>
              import("../views/dashboard/reporting/deliveryReport.vue"),
          },
          {
            path: "/voice/campaign/delivery_report",
            name: "voice delivery report",
            meta: { authRequired: true },
            component: () =>
              import("../views/dashboard/reporting/deliveryReport.vue"),
          },
        ],
      },
      {
        path: "/voice/messages",
        name: "voice-channel",
        meta: {
          authRequired: true,
          beforeResolve(routeTo, routeFrom, next) {
            if (logintType() == "voice_messages") {
              next();
            } else {
              next({ name: "messages" });
            }
          },
        },
        component: () => import("../views/dashboard/voice/index.vue"),
      },
      {
        path: "/voice/scheduled-message",
        name: "scheduled voice message",
        meta: {
          authRequired: true,
          beforeResolve(routeTo, routeFrom, next) {
            if (logintType() == "voice_messages") {
              next();
            } else {
              next({ name: "scheduled messages" });
            }
          },
        },
        component: () =>
          import("../views/dashboard/voice/scheduledVoiceMessage.vue"),
      },
      {
        path: "/developer",
        name: "developer",
        meta: { authRequired: true },
        redirect: "/developer/api_v1",
        component: () => import("../views/dashboard/developer/home.vue"),
        children: [
          {
            path: "/developer/api_v1",
            name: "API V2",
            meta: { authRequired: true },
            component: () =>
              import("../views/dashboard/developer/version1.vue"),
          },
          {
            path: "/developer/api_v2",
            name: "API 2",
            meta: { authRequired: true },
            component: () =>
              import("../views/dashboard/developer/version2.vue"),
          },
        ],
      },
      {
        path: "/market-place",
        name: "market place",
        meta: { authRequired: true },
        component: () => import("../views/dashboard/marketplace/home.vue"),
      },
    ],
  },
  // admin routes
  {
    path: "/admin",
    name: "admin-dashboard",
    redirect: "/admin/overview",
    component: () => import("../views/admin/index.vue"),
    children: [
      {
        path: "/admin/users",
        name: "users",
        component: () => import("../views/admin/users/index.vue"),
        children: [
          {
            path: "/admin/users/all",
            name: "all users",
            meta: { authRequired: true, isAdmin: true },
            component: () => import("../views/admin/users/users.vue"),
          },
          {
            path: "/admin/users/userSegments",
            name: "User Segments",
            meta: { authRequired: true, isAdmin: true },
            component: () => import("../views/admin/users/userSegments.vue"),
            children: [
              {
                path: "goldusers",
                name: "gold users",
                meta: { authRequired: true, isAdmin: true },
                component: () =>
                  import("../views/admin/users/userSegments/GoldUsers.vue"),
              },
              {
                path: "silverusers",
                name: "silver users",
                meta: { authRequired: true, isAdmin: true },
                component: () =>
                  import("../views/admin/users/userSegments/SilverUsers.vue"),
              },
              {
                path: "bronzeusers",
                name: "bronze users",
                meta: { authRequired: true, isAdmin: true },
                component: () =>
                  import("../views/admin/users/userSegments/BronzeUsers.vue"),
              },
            ],
          },
          {
            path: "/admin/users/corporate",
            name: "corporate users",
            meta: { authRequired: true, isAdmin: true },
            component: () => import("../views/admin/users/corporate.vue"),
          },
          {
            path: "/admin/users/sme",
            name: "SME users",
            meta: { authRequired: true, isAdmin: true },
            component: () => import("../views/admin/users/sme.vue"),
          },
          {
            path: "/admin/users/individual",
            name: "individual users",
            meta: { authRequired: true, isAdmin: true },
            component: () => import("../views/admin/users/individual.vue"),
          },

          {
            path: "/admin/users/fraudulent",
            name: "fraudulent users",
            component: () => import("../views/admin/users/taggedUsers.vue"),
          },
          {
            path: "/admin/users/reseller_fraudulent",
            name: "reseller fraudulent users",
            component: () => import("../views/admin/users/taggedUsers.vue"),
          },
          {
            path: "/admin/users/inactive",
            name: "inactive users",
            meta: { authRequired: true, isAdmin: true },
            component: () => import("../views/admin/users/inactiveUsers.vue"),
          },
          {
            path: "/admin/users/new-users",
            name: "New Users",
            meta: { authRequired: true, isAdmin: true },
            component: () => import("../views/admin/users/newusers.vue"),
          },
          {
            path: "/admin/reseller-users/all",
            name: "Reseller Users",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import("../views/admin/users/reseller/allUsers.vue"),
          },
        ],
      },
      {
        path: "/admin/sender_ids",
        name: "senderids",
        redirect: "/admin/sender_ids/pending",
        component: () => import("../views/admin/senderIDs/index.vue"),
        children: [
          {
            path: "/admin/sender_ids/pending",
            name: "pending sender ids",
            meta: { authRequired: true, isAdmin: true },
            component: () => import("../views/admin/senderIDs/pending.vue"),
          },
          {
            path: "/admin/sender_ids/declined",
            name: "declined sender IDs",
            meta: { authRequired: true, isAdmin: true },
            component: () => import("../views/admin/senderIDs/Declined.vue"),
          },
          {
            path: "/admin/sender_ids/pendingUserVerification",
            name: "pending user verification sender IDs",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import("../views/admin/senderIDs/PendingUserVerification.vue"),
          },
          {
            path: "/admin/sender_ids/approved",
            name: "approved sender IDs",
            meta: { authRequired: true, isAdmin: true },
            component: () => import("../views/admin/senderIDs/Approved.vue"),
          },
          {
            path: "/admin/sender_ids/autoApproved",
            name: "autoApproved sender IDs",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import("../views/admin/senderIDs/autoApproved.vue"),
          },
          {
            path: "/admin/sender_ids/ReservedSenderIDs",
            name: "Reserved Sender IDs",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import("../views/admin/senderIDs/ReservedSenderIDs.vue"),
          },
        ],
      },
      {
        path: "/admin/sender_ids/reseller",
        name: "reseller",
        component: () => import("../views/admin/senderIDs/Reseller.vue"),
        children: [
          {
            path: "/admin/sender_ids/PendingReseller",
            name: "pending reseller sender IDs",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import("../views/admin/senderIDs/PendingReseller.vue"),
          },
          {
            path: "/admin/sender_ids/DeclinedReseller",
            name: "declined reseller sender IDs",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import("../views/admin/senderIDs/DeclinedReseller.vue"),
          },
          {
            path: "/admin/sender_ids/approvedReseller",
            name: "approved reseller sender IDs",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import("../views/admin/senderIDs/approvedReseller.vue"),
          },
          {
            path: "/admin/sender_ids/onholdReseller",
            name: "onhold reseller sender IDs",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import("../views/admin/senderIDs/onHoldReseller.vue"),
          },
        ],
      },
      {
        path: "/admin/mass_messages",
        name: "message messages",
        meta: { authRequired: true, isAdmin: true },
        component: () => import("../views/admin/mass_messages/index.vue"),
      },
      {
        path: "/admin/notification",
        name: "admin notifications home",
        meta: { authRequired: true, isAdmin: true },
        redirect: "/admin/notifications",
        component: () => import("../views/admin/notifications/index.vue"),
        children: [
          {
            path: "/admin/notifications",
            name: "Notification Strips",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import("../views/admin/notifications/notifications.vue"),
          },
          {
            path: "/admin/birthdays-and-anniversaries",
            name: "Birthdays and Anniversaries",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import(
                "../views/admin/notifications/birthdaysAndAnniversaries.vue"
              ),
          },
          {
            path: "/admin/feedbacks",
            name: "Clients Feedbacks",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import("../views/admin/notifications/feedbacks.vue"),
          },
        ],
      },
      {
        path: "/admin/resellers",
        name: "admin resellers",
        meta: { authRequired: true, isAdmin: true },
        redirect: "/admin/resellers/pending",
        component: () => import("../views/admin/resellers/index.vue"),
        children: [
          {
            path: "pending",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import(
                "../views/admin/resellers/resellerRequests/pendingReseller.vue"
              ),
          },
          {
            path: "completed",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import(
                "../views/admin/resellers/resellerRequests/approveReseller.vue"
              ),
          },
          {
            path: "declined",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import(
                "../views/admin/resellers/resellerRequests/declinedReseller.vue"
              ),
          },
          {
            path: "sms-reports",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import("../views/admin/resellers/reports/sms/smsReports.vue"),
          },
          {
            path: "voice-reports",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import("../views/admin/resellers/reports/voice/voiceReports.vue"),
          },
        ],
      },
      {
        path: "/admin/promo_codes",
        name: "admin promos",
        meta: {
          authRequired: true,
          isAdmin: true,
        },
        redirect: "/admin/promo_codes/all",
        component: () => import("../views/admin/promos/index.vue"),
        children: [
          {
            path: "/admin/promo_codes/all",
            name: "admin promo all",
            meta: {
              authRequired: true,
              isAdmin: true,
              header: "Promo Codes",
            },
            component: () => import("../views/admin/promos/all.vue"),
          },
          {
            path: "/admin/promo_codes/active",
            name: "admin promos active",
            meta: {
              authRequired: true,
              isAdmin: true,
              header: "Active Promo Codes",
            },
            component: () => import("../views/admin/promos/active.vue"),
          },
          {
            path: "/admin/promo_codes/inactive",
            name: "admin promos inactive",
            meta: {
              authRequired: true,
              isAdmin: true,
              header: "Inactive Promo Codes",
            },
            component: () => import("../views/admin/promos/inactive"),
          },
          {
            path: "/admin/promo_codes/expired",
            name: "admin promos expired",
            meta: {
              authRequired: true,
              isAdmin: true,
              header: "Expired Promo Codes",
            },
            component: () => import("../views/admin/promos/expired.vue"),
          },
        ],
      },
      {
        path: "/admin/referrals",
        name: "admin referrals",
        meta: {
          authRequired: true,
          isAdmin: true,
        },
        component: () => import("../views/admin/referrals/index.vue"),
      },
      {
        path: "/admin/referrals/user/:id",
        name: "admin referrals user",
        meta: {
          authRequired: true,
          isAdmin: true,
        },
        component: () => import("../views/admin/referrals/ReferralUser.vue"),
      },
      {
        path: "/admin/industries",
        name: "admin industries",
        component: () => import("../views/admin/industries/industries.vue"),
      },
      {
        path: "/admin/reporting",
        name: "admin reporting",
        meta: { authRequired: true, isAdmin: true },
        redirect: "/admin/reporting/industries",
        component: () => import("../views/admin/reporting/index.vue"),
        children: [
          {
            path: "/admin/reporting/industries",
            name: "admin industries reporting",
            meta: { authRequired: true, isAdmin: true, header: "Industries" },

            component: () =>
              import("../views/admin/reporting/industries/index.vue"),
          },
          {
            path: "/admin/reporting/industries/:id/users",
            name: "admin users",
            meta: {
              authRequired: true,
              isAdmin: true,
              header: "Industry Users",
            },
            component: () =>
              import("../views/admin/reporting/industries/industryUsers.vue"),
          },
          {
            path: "/admin/reporting/quality_routes",
            name: "admin quality routes report",
            meta: { authRequired: true, isAdmin: true, header: "Sender IDs" },
            component: () =>
              import(
                "../views/admin/reporting/quality_routes/qualityRoutesReport.vue"
              ),
          },
          {
            path: "/admin/reporting/sender_names",
            name: "admin sender names report",
            meta: { authRequired: true, isAdmin: true, header: "Sender IDs" },
            component: () =>
              import(
                "../views/admin/reporting/sender_names/senderNamesReport.vue"
              ),
          },
          {
            path: "/admin/reporting/message_type",
            name: "admin message type",
            meta: { authRequired: true, isAdmin: true, header: "Message Type" },
            component: () =>
              import("../views/admin/reporting/messages/type.vue"),
          },
          {
            path: "/admin/reporting/business_chasis",
            name: "admin business chasis",
            meta: {
              authRequired: true,
              isAdmin: true,
              header: "Business Chasis",
            },
            component: () =>
              import("../views/admin/reporting/business_chasis/index.vue"),
          },
          {
            path: "/admin/reporting/sales&market_BI",
            name: "admin sales and market_BI",
            meta: {
              authRequired: true,
              isAdmin: true,
              header: "Sales And Market BI ",
            },
            component: () =>
              import("../views/admin/reporting/salesAndMarketBi"),
          },
          {
            path: "/admin/reporting/message_summary",
            name: "admin message summary",
            meta: {
              authRequired: true,
              isAdmin: true,
              header: "Message Summary",
            },
            component: () =>
              import("../views/admin/reporting/messages/summary.vue"),
          },
          {
            path: "/admin/reporting/theo-bot",
            name: "Admin Theo Bot",
            meta: {
              authRequired: true,
              isAdmin: true,
              header: "Theo Bot",
            },
            component: () =>
              import("../views/admin/reporting/theobot/index.vue"),
          },
        ],
      },
      {
        path: "/admin/finance",
        name: "admin finance",
        meta: { authRequired: true, isAdmin: true },
        component: () => import("../views/admin/finance/index.vue"),
      },
      {
        path: "/admin/finance/history",
        name: "admin finance history",
        redirect: "/admin/finance/bundle_history",
        meta: { authRequired: true, isAdmin: true },
        component: () => import("../views/admin/finance/financeHistory.vue"),
        children: [
          {
            path: "/admin/finance/bundle_history",
            name: "admin-finance_bundle",
            meta: {
              authRequired: true,
              isAdmin: true,
              header: "Bundle History",
            },
            component: () => import("../views/admin/finance/bundleHistory.vue"),
          },
          {
            path: "/admin/finance/wallet_history",
            name: "admin-finance_wallet",
            meta: {
              authRequired: true,
              isAdmin: true,
              header: "Wallet History",
            },
            component: () => import("../views/admin/finance/walletHistory.vue"),
          },
          {
            path: "/admin/finance/debtors_list",
            name: "admin-finance_debtors",
            meta: { authRequired: true, isAdmin: true, header: "Debtors List" },
            component: () => import("../views/admin/finance/debtorsList.vue"),
          },
          {
            path: "/admin/finance/debtors_list/:id",
            name: "admin-finance_debtors_details",
            meta: { authRequired: true, isAdmin: true, header: "Debtors List" },
            component: () =>
              import("../views/admin/finance/debtorsListSpecific.vue"),
          },
        ],
      },
      {
        path: "/admin/international_rates",
        name: "admin international rates",
        meta: { authRequired: true, isAdmin: true },
        component: () =>
          import("../views/admin/international_messaging/index.vue"),
      },
      {
        path: "/admin/wallet_history_fr",
        name: "french wallet history",
        meta: { authRequired: true, isAdmin: true },
        component: () => import("../views/admin/wallet_history_fr"),
      },
      {
        path: "/admin/settings",
        name: "admin-settings",
        component: () => import("../views/admin/settings/index.vue"),
        redirect: "/admin/settings/set_sms_voice_rate",
        children: [
          {
            path: "/admin/settings/set_sms_voice_rate",
            name: "Set SMS and Voice Rate",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import("../views/admin/settings/setSMSVoiceRate.vue"),
          },
          {
            path: "/admin/settings/chart_of_accounts",
            name: "Chart Of Accounts",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import("../views/admin/settings/chartAccounts.vue"),
          },
          {
            path: "/admin/settings/payment_aggregators",
            name: "Payment Aggregators",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import("../views/admin/settings/paymentAggregator.vue"),
          },
        ],
      },
      {
        path: "/admin/fraud-detection",
        meta: { authRequired: true, isAdmin: true },
        component: () => import("../views/admin/fraud_detection/index.vue"),
        children: [
          {
            path: "",
            name: "Fraud Messages",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import("../views/admin/fraud_detection/fraudulent_messages.vue"),
          },
          {
            path: "reseller",
            name: "Reseller Fraud Messages",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import(
                "../views/admin/fraud_detection/reseller_fraudulent_messages.vue"
              ),
          },
          {
            path: "whitelist",
            name: "WhiteList",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import("../views/admin/fraud_detection/whitelist_users.vue"),
          },
          {
            path: "keywords",
            name: "Keywords",
            meta: { authRequired: true, isAdmin: true },
            component: () =>
              import("../views/admin/fraud_detection/keywords.vue"),
          },
        ],
      },
    ],
  },
];

export default routes;
