import apiService from "../../services/api.service";

export const state={
    showBanner:false,
    message:'',
    type:''

};

export const mutations={
    SET_SHOW_BANNER(state, payload) {
        state.showBanner = payload;
      },
      SET_MESSAGE(sate,payload){
        state.message = payload;
      },
      SET_TYPE(state,payload){
        state.type=payload;
      },
      
    };

export const actions = {
    getActiveNotifications({commit}) {
        return new Promise( (resolve, reject) => {
            apiService.get(`api/notifications/active`)
                .then( ({ data: { data }}) => {
                    if(data) {
                        commit('SET_SHOW_BANNER',data)
                    }
                    resolve(data)
                }).catch( err => reject(err))
        })
    },
};
export const getters ={
    showBanner: (state) => state.showBanner,
    message: (state) => state.message,
    type: (state) => state.type
}

