import adminService from "@/services/admin.service";

/**
 * @typedef {typeof state} FraudSystemState
 * @typedef {keyof mutations} FraudSystemMutationOptions
 * @typedef {keyof actions} FraudSystemActionOptions
 */

export const state = {
  fraudulent_messages: [],
  keywords: [],
  reseller_messages: [],
  initialFraudTotal: 0,
  initialKeywordTotal: 0,
};

export const mutations = {
  SET_FRAUDULENT_MESSAGES(state, payload) {
    state.fraudulent_messages = payload;
    state.initialFraudTotal = payload.total;
  },
  SET_KEYWORDS(state, payload) {
    state.keywords = payload;
  },
  SET_RESELLER_MESSAGES(state, payload) {
    state.reseller_messages = payload;
    state.initialFraudTotal = payload.total;
  },
};

export const actions = {
  /**
   *
   * @param {{commit:(mutation:MutationOptions)=>unknown}} param0
   * @param {{page:number,search:string}} payload
   */
  async getFraudulentMessages({ commit }, payload) {
    const response = await adminService.get(
      `api/get/fraud/sms?page=${payload.page}&search=${payload.search}`
    );
    commit("SET_FRAUDULENT_MESSAGES", response.data.data);
    return response.data.data;
  },
  /**
   *
   * @param {{commit:(mutation:MutationOptions)=>unknown}} param0
   * @param {{user_id:string,fraud_ignore_status:0|1}} payload
   */
  async toggleFraudWhitelist({ commit }, payload) {
    const response = await adminService.post("api/toggle/fraud/user", payload);
    return response.data;
  },
  /**
   *
   * @param {{commit:(mutation:MutationOptions)=>unknown}} param0
   * @param {{page:number,search:string}} payload
   */
  async getKeywords({ commit }, payload) {
    const response = await adminService.get(
      `api/get/fraud/keywords?page=${payload.page}&search=${payload.search}`
    );
    commit("SET_KEYWORDS", response.data.data);
    return response.data.data;
  },

  async addKeyword({ commit }, payload) {
    const response = await adminService.post("api/add/fraud/keyword", payload);
    return response.data;
  },

  async deleteKeyword({ commit }, payload) {
    const response = await adminService.post(
      "api/delete/fraud/keyword",
      payload
    );
    return response.data;
  },

  // ! RESELLER ACTIONS
  /**
   *
   * @param {{commit:(mutation:MutationOptions)=>unknown}} param0
   * @param {{page:number,search:string}} payload
   */
  async getResellerFraudulentMessages({ commit }, payload) {
    const response = await adminService.get(
      `api/get/reseller/fraud/sms?page=${payload.page}&search=${payload.search}`
    );
    commit("SET_RESELLER_MESSAGES", response.data.data);
    return response.data.data;
  },
  /**
   *
   * @param {{commit:(mutation:MutationOptions)=>unknown}} param0
   * @param {{user_id:string,fraud_ignore_status:0|1}} payload
   */
  async toggleResellerFraudWhitelist({ commit }, payload) {
    const response = await adminService.post(
      "api/toggle/reseller/fraud/user",
      payload
    );
    return response.data;
  },
};

export const getters = {
  fraudulentMessages: (state) => state.fraudulent_messages,
  keywords: (state) => state.keywords,
  resellerMessages: (state) => state.reseller_messages,
};
