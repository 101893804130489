import apiService from "../../services/api.service";

export const state = {
    all_groups: [],
    fetchingData: false
}

export const mutations = {

}

export const actions = {
    createApiV1({commit},) {
        return new Promise( (resolve, reject) => {
            apiService.get(`api/v1/key/create`)
                .then(  ({ data }) => {
                    resolve(data)
                }).catch( err => reject(err))
        })
    },
    deleteApiV1({commit}, payload) {
        return new Promise( (resolve, reject) => {
            apiService.post(`api/v1/key/delete/${payload.id}`)
                .then(  ({ data }) => {
                    resolve(data)
                }).catch( err => reject(err))
        })
    },
    getApiV1({commit}, payload) {
        return new Promise( (resolve, reject) => {
            apiService.get(`api/v1/keys/get`)
                .then(  ({ data : { data } }) => {
                    resolve(data)
                }).catch( err => reject(err))
        })
    },
    // API V2
    createApiV2({commit}, payload) {
        return new Promise( (resolve, reject) => {
            apiService.post(`api/v2/key/create`,payload)
                .then(  ({ data }) => {
                    resolve(data)
                }).catch( err => reject(err))
        })
    },
    deleteApiV2({commit}, {id,key}) {
        return new Promise( (resolve, reject) => {
            apiService.post(`api/v2/key/delete/${id}`)
                .then(  ({ data }) => {
                    resolve(data)
                }).catch( err => reject(err))
        })
    },
    getApiV2({commit}) {
        return new Promise( (resolve, reject) => {
            apiService.get(`api/v2/keys/get`)
                .then(  ({ data : { data} }) => {
                    resolve(data)
                }).catch( err => reject(err))
        })
    },
    enableApiV2({commit}, id) {
        return new Promise( (resolve, reject) => {
            apiService.post(`api/v2/key/enable/${id}`)
                .then(  ({ data }) => {
                    resolve(data)
                }).catch( err => reject(err))
        })
    },
    disableApiV2({commit}, id) {
        return new Promise( (resolve, reject) => {
            apiService.post(`api/v2/key/disable/${id}`)
                .then(  ({ data }) => {
                    resolve(data)
                }).catch( err => reject(err))
        })
    },
    addLimitApiV2({commit},payload) {
        return new Promise( (resolve, reject) => {
            apiService.post(`api/v2/add/limit/${payload.id}`,payload.body)
                .then(  ({ data }) => {
                    resolve(data)
                }).catch( err => reject(err))
        })
    },

}
