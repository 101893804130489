import apiService from "../../services/api.service";

export const state = {
  referralPoints: {},
};

export const mutations = {
  SET_REFERRAL_POINTS(state, payload) {
    state.referralPoints = payload.data;
  },
};

export const actions = {
  getReferralPoints({ commit }) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/get/referral/details`)
        .then((data) => {
          if (data) {
            commit("SET_REFERRAL_POINTS", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
};
export const getters = {
  referralPoints: (state) => state.referralPoints,
};
