<template>
  <button id="export">
      Export
  </button>
</template>

<script>
export default {

}
</script>

<style scoped>
#export{
    width: 92px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F7921C;
    border-radius: 5px;
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #FFFFFF;
    outline: none;
    border:none;
}
</style>