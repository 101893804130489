import Storage from "@/services/storage";
import axios from "axios";
const storage = new Storage();
import authHeader from "./admin.auth.header";
import router from "../router";

class ApiService {
  post(path, data, re) {
    return axios.post(`${process.env.VUE_APP_ENGINE_URL}/${path}`, data, {
      headers: authHeader(),
    });
  }

  update(path, data) {
    return axios.put(`${process.env.VUE_APP_ENGINE_URL}/${path}`, data, {
      headers: authHeader(),
    });
  }

  delete(path) {
    return axios.delete(`${process.env.VUE_APP_ENGINE_URL}/${path}`, {
      headers: authHeader(),
    });
  }

  get(path) {
    return axios.get(`${process.env.VUE_APP_ENGINE_URL}/${path}`, {
      headers: authHeader(),
    });
  }
}

export default new ApiService();