<template>
  <button @click="back" class="backBtn"><img height="17" src="@/assets/left-arrow (1).svg" alt="img"></button>
</template>

<script>
export default {
    methods:{
        back() {
            this.$router.push('/profile-settings').catch((err) => {
                if (err.name !== "NavigationDuplicated") throw err;
             })
        }
    }
}
</script>

<style scoped>
.backBtn{
	width: 32px;
	height: 32px;
	background: #F3F3F4;
	border-radius: 5px;
	outline:none;
	border:none;
	margin-right:20px;
    text-align: center;
}
</style>