import apiService from "../../services/api.service";

export const actions = {
  sendFeedback({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/leave/feedback`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  featureRequest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/feature/request`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
};
