<template>
  <div class="col-md-12">
    <div class="row mt-5 pt-5">
        <div class="col-md-12" v-loading="loading">

        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        loading: {
            required: true,
            type: Boolean
        }
    }
}
</script>

<style>

</style>