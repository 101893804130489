import AdminService from "@/services/admin.service";

export const state = {
    feedbacks: []
}

export const mutations = {
    SET_FEEDBACK(state, payload){
        state.feedbacks = payload.sort((a, b) => new Date(b.created_at) - new Date(a.created_at) )
    }
}
export const actions = {
    getFeedback({commit}) {
        return new Promise( (resolve, reject) => {
            AdminService.get(`api/get/feedbacks`)
                .then( ({ data: { data }}) => {
                    
                    if(data) {
                        commit('SET_FEEDBACK',data)
                    }
                    resolve(data)
                }).catch( err => reject(err))
        })
    },
}

export const getters = {
    feedbacks: state => state.feedbacks
}