import AdminService from "@/services/admin.service";

export const state = {
    industries: []
}

export const mutations = {
    SET_INDUSTRIES(state, payload){
        state.industries = payload
    },
}

export const actions = {
    getIndustries({commit}) {
        return new Promise( (resolve, reject) => {
            AdminService.get(`api/industries/get`)
                .then( ({ data: { data }}) => {
                    
                    if(data) {
                        commit('SET_INDUSTRIES',data)
                    }
                    resolve(data)
                }).catch( err => reject(err))
        })
    },
    messageClients({commit}, payload) {
        return new Promise( (resolve, reject) => {
            AdminService.post(`api/mass/messaging`,payload)
                .then( ({ data: { message }}) => {
                    resolve(message)
                }).catch( err => reject(err))
        })
    },
    messageDebtors({commit},payload) {
        return new Promise( (resolve, reject) => {
            AdminService.post(`api/debtor/message`,payload)
                .then( ({ data: { message }}) => {
                    resolve(message)
                }).catch( err => reject(err))
        })
    }, //
}

export const getters = {
    industries: state => state.industries
}
