import Vue from "vue";
import VueI18n from "vue-i18n";
import storage from "@/services/storage.js";
import frLocale from "element-ui/lib/locale/lang/fr";
import enLocale from "element-ui/lib/locale/lang/en";

Vue.use(VueI18n);
var Storage = new storage();

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
      //  attach element-ui locale to the messages
      if (locale === "en") {
        messages[locale] = { ...messages[locale], ...enLocale };
      } else if (locale === "fr") {
        messages[locale] = { ...messages[locale], ...frLocale };
      }
    }
  });
  return messages;
}

export default new VueI18n({
  locale:
    Storage.getSavedState("currentLanguage") ||
    process.env.VUE_APP_I18N_LOCALE ||
    "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
});
